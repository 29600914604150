<template>
  <div class="bg-white rounded-b-md rounded-tr-md pt-4">
    <table class="table-auto w-full">
      <thead class="text-left text-sm text-lightBlack bg-oliveGreen">
        <tr class="border-t border-b border-[#e7e6e6]">
          <th class="py-2 pl-[5.25rem]">
            {{ $t('assetManagement.objectGroups-name') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-id') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-organisation') }}
          </th>
          <th class="py-2 text-center" v-show="showScore">
            {{ $t('assetManagement.objectGroups-score') }}
          </th>
          <th class="text-center py-2 pr-11">
            {{ $t('assetManagement.objectGroups-actions') }}
          </th>
        </tr>
      </thead>

      <tbody class="text-lightBlack">
        <template v-if="groupsPage.length > 0">
          <ObjectGroupRow v-for="(group, idx) in groupsPage" :key="idx" :group="group" @updateToActiveQuestionnaire="
            openUpdateToActiveQuestionnaireDialog(group)
            " @edit="openEditDialog(group)" @delete="openDeleteDialog(group)" @add="openCreateObjectDialog(group)"
            @edit:object="openObjectEdit" @submit="openSubmitForReviewDialog(group)"
            @downloadPage="openDownloadPage(group)" @showScoreToRow="showScoreFunction" :showScore="showScore" />
          <tr v-for="idx in numPlaceholders" :key="idx" class="item-row"></tr>
        </template>

        <tr v-else>
          <td class="text-center font-medium py-16" colspan="4">
            {{
              pending
              ? `${$t('general.loading')} ...`
              : error
                ? `${$t('table.loadError')}.`
                : `${$t('table.empty')}.`
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <UpdateToActiveQuestionnaireDialog v-model="updateToActiveQuestionnaireDialog" :assetGroupId="selectedGroup?.id"
      :isBuyerAsset="false" />

    <EditObjectGroupDialog v-model="editDialog" :group="selectedGroup" @finish="editGroup" />
    <DeleteObjectGroupDialog v-model="deleteDialog" :group="selectedGroup" @delete="deleteGroup" />
    <CreateObjectDialog v-model="createObjectDialog" :group="selectedGroup" @create="objectCreated" />
    <AuditQuestionnaireDialog v-model="submitForReviewDialog" :group="selectedGroup" :id="selectedGroup?.id ?? ''"
      :name="selectedGroup?.name ?? ''" :isForGroup="true" @finish="changeGroupReviewStatus" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ObjectGroupRow } from '@/components/dataTable'
import {
  DeleteObjectGroupDialog,
  CreateObjectDialog,
  EditObjectGroupDialog,
  UpdateToActiveQuestionnaireDialog,
  AuditQuestionnaireDialog,
} from '@/components/dialog'
import { useListFetch } from '@/composition'
import { general } from '@/helpers'

export default {
  components: {
    ObjectGroupRow,
    DeleteObjectGroupDialog,
    CreateObjectDialog,
    EditObjectGroupDialog,
    UpdateToActiveQuestionnaireDialog,
    AuditQuestionnaireDialog,
  },
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'objectGroups-list-fetch-error',
      })
    return useListFetch('objectGroups', errorMessage)
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      updateToActiveQuestionnaireDialog: false,
      editDialog: false,
      deleteDialog: false,
      createObjectDialog: false,
      submitForReviewDialog: false,
      selectedGroup: null,
      showScore: false,
    }
  },
  inject: ['newGroup', 'newObject', 'deleteObjectGroup', 'createObject'],
  computed: {
    numPlaceholders() {
      if (
        this.totalItems <= this.pageSize ||
        this.groupsPage.length === this.pageSize
      ) {
        return 0
      }
      return this.pageSize - (this.groupsPage.length % this.pageSize)
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedGroups() {
      const searchKeys = ['id', 'name', 'organisationName']
      return general.searchOverKeys(this.list, searchKeys, this.searchTerm)
    },
    groupsPage() {
      return this.searchedGroups.slice(...this.pageSlice)
    },
  },
  methods: {
    openUpdateToActiveQuestionnaireDialog(group) {
      this.selectedGroup = group
      this.updateToActiveQuestionnaireDialog = true
    },
    openEditDialog(group) {
      this.selectedGroup = group
      this.editDialog = true
    },
    openDeleteDialog(group) {
      this.selectedGroup = group
      this.deleteDialog = true
    },
    openCreateObjectDialog(group) {
      this.selectedGroup = group
      this.createObjectDialog = true
    },
    openSubmitForReviewDialog(group) {
      this.selectedGroup = group
      this.submitForReviewDialog = true
    },
    openDownloadPage(group) {
      this.$router.push({
        name: 'certificate',
        params: { assetId: group.id, isGroup: true },
      })
    },
    openObjectEdit(id, isBuyerAsset) {
      this.$router.push({ name: 'editObject', params: { assetId: id, isBuyerAsset } })
    },
    editGroup(group) {
      const idx = this.list.findIndex(g => g.id === group.id)
      this.list[idx] = group
    },
    changeGroupReviewStatus(groupId) {
      const idx = this.list.findIndex(g => g.id === groupId)
      this.list[idx].canBeSubmitted = false
    },
    deleteGroup(group) {
      this.list = this.list.filter(g => g.id !== group.id)
      this.deleteObjectGroup()
    },
    objectCreated(object) {
      if (!this.selectedGroup.assets) {
        this.selectedGroup.assets = []
      }
      this.selectedGroup.assets.push({
        assetId: object.id,
        name: object.name,
      })
      this.createObject()
    },
    showScoreFunction({ group, score }) {
      this.showScore = true;
      this.selectedGroup = group
      this.selectedGroup.score = score
    }
  },
  watch: {
    'searchedGroups'(newList) {
      this.$emit('update:table', newList.length)
    },
    'newGroup.value'(group) {
      this.list.push(group)
    },
    'newObject.value'(object) {
      this.list
        .find(group => group.id === object.assetGroup.id)
        .assets.push(object)
    },
  },
}
</script>

<style scoped>
.item-row {
  @apply border-lighterGray border-b;
}
</style>
